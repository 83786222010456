











































import gsap from 'gsap'
import { useGetters } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'

import MagazineCategories from '@/components/magazine/Categories.vue'
import UiFlexible from '@/components/ui/Flexible.vue'
import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'MagazineSubcategoryPersonality',
  components: {
    UiFlexible,
    MagazineCategories,
    UiPicture,
  },
  props: {},

  setup() {
    const rootEl = ref<HTMLElement | null>(null)
    const { content } = useGetters(['content'])

    // Appear animation for hero
    const onHeroAppear = (el: HTMLElement) => {
      const titleEl = el.querySelector('.magazine-tv__hero__title')
      const textEls = el.querySelectorAll('.magazine-tv__hero__text > *')
      const liknEl = el.querySelectorAll('.magazine-tv__hero__link')
      const imgEl = el.querySelector(
        '.magazine-tv__hero__picture .picture__container'
      )
      const breakpoint = 960
      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set([el, titleEl, textEls, imgEl], { clearProps: 'all' })
        },
      })

      tl.set(el, { clearProps: 'opacity' })

      if (window.innerWidth < breakpoint) {
        tl.from(imgEl, { opacity: 0, scale: 1.1, duration: 1.2 }, 0).from(
          [titleEl, textEls, liknEl],
          {
            opacity: 0,
            y: 20,
            stagger: 0.1,
            duration: 1,
          },
          0
        )
      } else {
        tl.from(imgEl, { opacity: 0, scale: 1.1, duration: 1.2 }, 0).from(
          [titleEl, textEls, liknEl],
          {
            opacity: 0,
            x: 30,
            stagger: 0.1,
            duration: 1,
          },
          0
        )
      }
    }

    // On link click, scroll to episodes
    const onAnchorClick = () => {
      const episodesEl = rootEl.value?.querySelector('[class*="card-slider"]')

      if (episodesEl) {
        const { top } = episodesEl.getBoundingClientRect()
        const headerHeight =
          document.querySelector('.header')?.clientHeight || 0
        const categoriesHeight =
          document.querySelector('.magazine-categories ')?.clientHeight || 0

        window.scrollTo({
          top: top - headerHeight - categoriesHeight + window.scrollY,
          left: 0,
          behavior: 'smooth',
        })
      }
    }

    return {
      rootEl,
      content,
      onHeroAppear,
      onAnchorClick,
    }
  },
})
